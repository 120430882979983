// import axios from 'axios';
export default {

    install(Vue, options) {

        let blankUserData = {
            id: 0,
            name: '',
            // firm_name: '',
            // short_firm_name: '',
            first_name: '',
            last_name: '',
            account_owner_type:'',
            birth_date: '',
            country: '',
            region: '',
            province: '',
            apartment: '',
            city: '',
            street: '',
            house_number: '',
            post_code: '',
            gender: '',
            phone: '',
            email: '',
            login: '',
            is_subscription_owner: 0,
            locale:'',
            email_verified_at:'',
            google2fa_enable:'',
            avatar: '',
            catalog: '',
            vk: '',
            fb: '',
            instagram: '',

            nr_pwz:'',
            experience_desc:'',
            schedule_interval:'',

            acceptations:{},

            permissions: [],
            doctor_types: [],
            roles: [],
        };

        let user = {

            auth: false,
            dbl_fa_confirmed: false, //2fa
            APP_INTERFACE:'',
            CURRENT_ROUTER:false,
            PREVIOUS_ROUTER:false,

            checkAuth() {

               return new Promise((resolve, reject) => {

                  return $http.publicAsync('get', 'api/auth/user', {},
                   function(response){
                        if(response.data.user){
                            user.dbl_fa_confirmed = true;
                            user.fillUser(response.data.user);
                        }

                       resolve(response);
                   }, function(error) {

                       if(error.response && error.response.status === 423 && in_array(error.response.data.redirect, ['change-password', 'verify-email', 'terms-and-conditions', 'confirm-2fa'])) {

                           if(error.response.data.user){

                               user.fillUser(error.response.data.user);

                               if(error.response.data.redirect){

                                   setTimeout(()=>{ //todo set interval to prevent closing modal by client
                                       if(error.response.data.redirect == 'verify-email'){


                                           if(window.location.pathname  != window.$_app.$router.resolve({name:'auth.not_verified'}).href){

                                               window.$_app.$router.push({name: 'auth.not_verified'}).then(function(){});
                                           } else {

                                           }

                                           // if(window.$_app.$route.name != 'auth.not_verified');


                                       } else if(error.response.data.redirect == 'confirm-2fa'){
                                           user.dbl_fa_confirmed = false;
                                           window.$_app.$router.push({name: 'auth.confirm_2fa'}).then(function(){});

                                       } else if(error.response.data.redirect == 'change-password'){

                                           window.$_app.eventBus.$emit('openPasswordResetModal');

                                       } else if(in_array(error.response.data.redirect, ['terms-and-conditions'])){
                                           window.$_app.$store.commit('app/setDisableSiteReason',error.response.data.redirect);
                                           window.$_app.eventBus.$emit('openDisabledSiteAccessModal',{reason:error.response.data.redirect});
                                       }
                                       resolve(error.response);
                                   }, 2000);
                               }

                               resolve(error.response);

                           } else{

                               user.clearUserData();
                               user.resolveFailRedirect();
                           }

                           // window.$_app.$store.state['app'].showGlobalModal();
                       }else if(error.response && error.response.status === 401){

                           user.clearUserData();
                           user.resolveFailRedirect();
                       } else {
                           // console.log('heeere 2');

                           user.clearUserData();
                           user.resolveFailRedirect();
                       }

                       reject(error);
                   });
               });

            },

            clearUserData(){

                localStorage.setItem('user', '');

                for(let prop in user){
                    //
                    if(blankUserData.hasOwnProperty(prop)){
                        console.log(typeof user[prop]);
                        if(typeof user[prop] != 'function'){
                            user[prop] = '';
                            Vue.set(user, prop, '');
                        }

                    }
                }
                user.auth = false;
                Vue.set(user, 'auth', false);
                console.log('logout',user);
            },

            async loginUser(data){

               return  new Promise(function(resolve, reject) {

                   $http.publicAsync('get','sanctum/csrf-cookie').then(response => { //server  will return XSRF token and laravel_session SET COOKIE header and browser will add it to cookie

                       data.timezone = window.$_app.getUserTimeZone();

                       $http.async(window.$_app, 'post','login', data, (resp) => {

                           // window.$_app.$preloader.show();

                           if(resp.data.user ) {

                               window.$_app.$sockets.initUserSocket(resp.data.user.id); //for auth user
                               window.$_app.$sockets.getIo().on('new_user_notification', function(){
                                   window.$_app.$store.commit('alerts/setNotifications', window.$_app.$store.state['alerts'].total_notification_count + 1);
                               });

                               user.fillUser(resp.data.user);

                               if(user.locale != window.$_app.$store.state['app'].locale){
                                   window.$_app.$store.dispatch('app/UPDATE_LOCALE', {locale:user.locale, set:true})
                               }
                               // localStorage.setItem('token', resp.data.token);

                               user.resolveLoginRedirect();

                           } else {

                               user.clearUserData();
                               user.resolveFailRedirect();
                           }
                           resolve(resp);

                       }).catch(function (error) {
                           reject(error);
                       });

                   }).catch(function (error) {
                       reject(error);
                   });
               });


            },

            verify2fa(data){
              return $http.async(window.$_app, 'get', 'api/auth/2fa-safety/verify', data, (resp)=>{
                   if(resp.data.user){
                       user.dbl_fa_confirmed = true;
                       user.fillUser(resp.data.user);
                       user.resolveLoginRedirect();
                   }

                }, function(error){});
            },

            loginSocial(provider){

                // return  new Promise(function(resolve, reject) {

                   return $http.publicAsync('get', 'sanctum/csrf-cookie').then(response => { //server  will return XSRF token and laravel_session SET COOKIE header and browser will add it to cookie
                        // window.location.href = window.$_app.$API_DOMAIN + 'testLogin' ;
                        window.location.href = window.$_app.$API_DOMAIN + 'register/' + provider;
                        // $http.async(window.$_app, 'post','login', data, (resp) => {
                        //
                        // });
                    }).catch(function (error) {
                        reject(error);
                    });
                // });

            },

            logout(data){
                return $http.async(window.$_app,'post','logout', {}, (resp) => {

                    window.$_app.$preloader.show();

                    this.clearUserData();
                    // localStorage.setItem('logoutEnv',window.$_app.$root.APP_INTERFACE);
                    window.$_app.$router.push('/login').then(function(){
                        window.$_app.$preloader.hide();
                    });

                });

            },

            register(regData) {

                return $http.async(window.$_app,'post','register', regData, (resp) => {
                    window.$_app.$preloader.show();
                    if(resp.data.user) {

                        this.fillUser(resp.data.user);
                        localStorage.setItem('token', resp.data.token);

                        this.resolveLoginRedirect();

                    } else {
                        this.clearUserData();
                        this.resolveFailRedirect();
                    }

                });

            },

            resolveLoginRedirect(){
                window.$_app.$router.push({name: this.account_owner_type+'.dashboard'});
            },

            resolveFailRedirect() {

                if (!user.auth) {
                        // window.$_app.$router.push({name: this.APP_INTERFACE.toLowerCase()+'.login'});
                        window.$_app.$router.push({name: 'guest.login'});
                }
            },

            fillUser(data, fromCache) {

                let loggedUser = Object.assign({}, blankUserData);

                loggedUser.password = null;

                for (let prop in data) {
                    if (loggedUser.hasOwnProperty(prop)) {
                        loggedUser[prop] = data[prop];
                        user[prop] = data[prop];
                        Vue.set(user, prop, data[prop]);
                    }
                }

                if(fromCache){
                    loggedUser.auth = user.auth = data.auth;
                    loggedUser.dbl_fa_confirmed = user.dbl_fa_confirmed = data.dbl_fa_confirmed;
                } else {

                    loggedUser.auth = user.auth = true;
                    loggedUser.dbl_fa_confirmed = user.dbl_fa_confirmed;

                    if (process.env.MIX_ENV == 'dev') {
                        localStorage.setItem('user', JSON.stringify(loggedUser));
                    } else {
                        localStorage.setItem('user', btoa(
                            encodeURIComponent(JSON.stringify(loggedUser)).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
                                return String.fromCharCode('0x' + p1)
                            })
                        ));

                    }

                    localStorage.setItem('locale', loggedUser.locale);
                }

                Vue.set(user, 'auth', loggedUser.auth);

            },

            initFromCachedData(){
                let u = localStorage.getItem('user');
                // console.log('from cached',u );
                if(u){

                    if (process.env.MIX_ENV == 'dev') {
                        u = JSON.parse(u);
                    } else {
                        u = JSON.parse(decodeURIComponent(
                            atob(u)
                                .split('')
                                .map(function (c) {
                                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                                })
                                .join('')
                        ));
                    }

                    this.fillUser(u, true);
                }
            },

            can(permission) {

                if(!user || !user.auth) {
                    return false;
                }
                let slugs = permission.split(':');
                let module = slugs[0];

                return user.permissions['site']?.includes('site:full_access') ||  user.permissions[module] && user.permissions[module].includes(permission);
            },

            canAll(permissions) {
                let isAllowed = true;
                    for(let i = 0; i < permissions.length; i++) {
                        if(!isAllowed) break;
                        isAllowed = user.can(permissions[i]);
                    }
                return isAllowed;
            },

            canSome(permissions) {

                for(let i = 0; i < permissions.length; i++) {
                    if(user.can(permissions[i])) return true
                }

            },

            // hasRole(role) {
            //     return false;
            //     // return user && user.roles.includes(role);
            // },
            getPreparedName(u){
                if(!u){
                //     u = user;
                    return  '--';
                }
                return  u.first_name + ' ' + u.last_name;
            }
        };

        user.initFromCachedData();


        Vue.prototype.$user = user;

        // Vue.computed({
        //
        // });

        Vue.mixin({
            data: function () {
                return {
                    $user: user
                };
            },
        });


    }
}

