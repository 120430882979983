var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-wrapper", attrs: { id: "app", id: _vm.appWrapperId } },
    [
      _c("vue-progress-bar"),
      _vm._v(" "),
      _c("router-view", {
        key: _vm.$root.reloadIndex,
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ Component }) {
              return [
                _c(
                  "transition",
                  { attrs: { name: "zoom-fade", mode: "out-in" } },
                  [_c(Component, { tag: "component" })],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }