import {EventBus} from "@helpers/utils/Event";
// import Vue from "vue";
import {showSweetMessage} from '@resources/js/helpers/utils'

export default {

    data() {
        return {
            eventBus: EventBus,
            // app_token: window.app_token,
            loadingWindow: null,
            // hostFanbaseImage: process.env.MIX_HOST_FANBASE_IMAGE,

        }
    },

    computed: {

        appCurrency(){
            return this.$store.state['app'].appCurrency;
        },

        appLocale(){
            return this.$store.state['app'].appLocale;
        },

        themeColor(){
            return this.$store.state['app'].themeColor;
        },

        notificationCount(){
            return this.$store.state['alerts'].total_notification_count;
        },

        currentBreakPoint(){
            // console.log('-----------------------------',this.$store.state['app'].currentBreakPoint);
            return this.$store.state['app'].currentBreakPoint;
        },

        windowWidth(){
            return  this.$store? this.$store.state['app'].windowWidth : window.innerWidth;
       },

        appWrapperId(){
            return this.$store.state['app'].APP_INTERFACE;
        },

        pageTemplateClass() {
            return this.$store.state['app'].pageTemplateClass;
        },
        isVerticalMenuCollapsed(){
            return this.$store.state['verticalMenu'].isVerticalMenuCollapsed;
        },
        // isVerticalMenuActive(){
        //     return this.$store.state['verticalMenu'].isVerticalMenuActive;
        // }
    },

    watch:{
        windowWidth(val) {
            // if ( val < this.$store.state['theme']['show-mobile-menu']) {
            //     // this.isVerticalMenuActive = false
            //     this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false);
            // }
            this.$store.commit('app/UPDATE_CURRENT_BREAK_POINT', val);
        },
    },

    methods: {
        consoleLog(...data) {
                console.log(...data);
        },
        showToast(variant = 'success', text = '', title = false){ //todo maybe separated toast

            const h = this.$createElement;

            const vNodesMsg = h(
                'p',
                { class: ['text-left', 'mb-0', 'white-space-pre-line'] },
                [
                    text,
                    // ' Flashy ',
                    // h('strong', 'toast'),
                    // ` message #${this.count} `,
                ]
            );
            // Create the title
            let icon  = 'CheckSquareIcon';
            let defaultTitle  = this.$t('app.success');
            if(variant == 'error' || variant == 'warning'){
                icon = 'AlertCircleIcon';
                defaultTitle  = this.$t('app.error');
            }
            if(variant == 'info'){
                icon = 'InfoIcon';
                defaultTitle  = this.$t('app.informations');
            }
            if(variant == 'error') variant = 'danger';
            const vNodesTitle = h(
                'div',
                { class: ['d-flex', 'flex-grow-1', 'mr-2', 'align-items-center'] },
                [
                    h('feather-icon', { class: 'mr-1',  props: {icon: icon, size: '16'} }),
                    h('strong', { class: 'mr-2' }, title? title : defaultTitle),
                ]
            )
            // Pass the VNodes as an array for message and title
            let toastData = {
                solid: true,
                variant: variant,
                autoHideDelay: 4000,
                title:  [vNodesTitle],
                transition: "Vue-Toastification__bounce",
            };

            this.$bvToast.toast([vNodesMsg], toastData)

        },
        showSweetMessage(text, icon){
            showSweetMessage(text, icon);
        },
        confirmAction(title, text, icon, successCallback, cancelCallback, dismissCallback, options){
            let self = this;
            let settings = {
                title: title,
                text: text,
                icon: icon,// 'success' | 'error' | 'warning' | 'info' | 'question'
                showCancelButton: true,
                confirmButtonText: self.$t('buttons.yes'),
                cancelButtonText: self.$t('buttons.cancel'),
                showClass: {
                    popup: 'animate__animated animate__flipInX',
                },
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-default ml-1',
                },
                buttonsStyling: false,
            };
            if(options){
                settings = Object.assign(settings, options);
            }
            console.log('conf_assign', settings, options);
            this.$swal(settings).then(result => {

                if (result.value) {

                    if (typeof successCallback === 'function') {
                        successCallback.apply(this, [result]);
                    }

                } else if (result.dismiss === 'cancel') {
                    if (typeof cancelCallback === 'function') {
                        cancelCallback.apply(this, [result]);
                    }
                } else if(result.dismiss === 'backdrop' || result.dismiss === 'esc') { //esc / outside click
                    if (typeof dismissCallback === 'function') {
                        dismissCallback.apply(this, [result]);
                    }
                }
            })
        },

        confirmDeleting(successCallback, errorCallback) {
            this.confirmAction(this.$t('app.deleting'), this.$t('app.no_restore_warning')+'!', 'warning', successCallback, errorCallback);
        },

        focusOnFormError: function (is_invalid) {
            if (is_invalid) {
                // this.$nextTick(() => {
                setTimeout(function () {
                    let elWithError = document.querySelector('form input.is-invalid');
                    if (!elWithError) {
                        elWithError = document.querySelector('form textarea.is-invalid');
                    }
                    if (!elWithError) {
                        elWithError = document.querySelector('form .is-invalid input');
                    }
                    if (elWithError) {
                        elWithError.focus();
                    }

                }, 300);
            }
        },
        getValidationState({ dirty, validated, required: fieldRequired, changed, valid = null }){
            const result = dirty || validated ? valid : null
            return !fieldRequired && !changed ? null : result
        },
        getValidationClass(validationProps){
            return {'invalid-input': (validationProps.validated && !validationProps.valid), 'valid-input':(validationProps.validated && validationProps.valid)};
        },
        rand(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        roundNumber(number, digits) {
            let multiple = Math.pow(10, digits);
            return Math.round(number * multiple) / multiple;
        },
        formatDate(val, toFormat, fromFormat, timezone) {
            return window.formatDate(val, toFormat, fromFormat, timezone)
        },
        formatLaraUtcToLocalTime(laraTime, toFormat, fromFormat = 'YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]'){
            return moment(moment.utc(laraTime, fromFormat).toDate()).format( toFormat);
        },
        formatSeconds(val, toFormat) {

            return window.formatSeconds(val, toFormat)
        },
        redirect(url) {
            window.location.replace(url);
        },
        reload(){
            window.location.reload();
        },

        reloadRootComponent(callback) {
            let vm = this;
            vm.$store.dispatch('app/GET_SITE_SETTINGS', {
                cache_force_update: true
            }).then(function(){
                vm.$root.reloadIndex = (new Date().getTime());
                if(typeof callback == 'function'){
                    vm.$nextTick(function(){
                        callback(vm);
                    });
                }
            });

        },

        refreshCacheSettings(){
            this.reloadRootComponent();
        },

        htmlEncode(s) {
            return window.htmlEncode(s);
        },
        in_array(v, array){
          return  window.in_array(v, array);
        },



         //todo remove

        // messageBox(title, message) {
        //     this.$alert(message, title || "Сообщение", {confirmButtonText: 'OK'});
        // },
        // loadingStart() {
        //     if(this.loadingWindow){
        //         this.loadingWindow.close();
        //         this.loadingWindow = null;
        //     }
        //     this.loadingWindow = this.$loading({
        //         lock: true,
        //         text: 'Загрузка',
        //         spinner: 'el-icon-loading',
        //         background: 'rgba(0, 0, 0, 0.7)'
        //     });
        // },
        // loadingEnd() {
        //     this.loadingWindow.close();
        //     this.loadingWindow = null;
        // },
        // messageBoxConfirm(title, message) {
        //     return this.$confirm(message, title || "Сообщение", {
        //         confirmButtonText: 'OK',
        //         cancelButtonText: 'Отмена',
        //         type: 'warning'
        //     });
        // }

        // previewFile(e, download_base_url, item){
        //     e.preventDefault();
        //     // let link = e.target.closest('.preview-firstly');
        //     item.download_base_url = download_base_url;
        //     this.$bvModal.show('preview-file-modal');
        //     this.$store.state.app.viewableFile = item;
        // },

        // config(val){
        //     return (val && this.$store.state.app.general_settings) ? this.$store.state.app.general_settings[val] : '';
        // },



        initCloseBlockOnBlur(button, closing_block, closeCallback, openCallback){

            let t = this;

            let toggleMenu = function toggleMenu() {
                closing_block.style.display = 'none';
            };

            document.addEventListener('click', function (e) {

                let target = e.target;
                let its_menu = target == closing_block || closing_block.contains(target);
                let its_button = target == button;
                let is_opened = closing_block.classList.contains('is-opened');

                if ( is_opened && !its_menu && !its_button ) {

                    if(typeof closeCallback === 'function'){
                        closeCallback(closing_block);
                    } else if(closeCallback === false){

                    } else {
                        toggleMenu();
                    }

                }
                // else if(its_menu) {
                //
                //     if(typeof openCallback === 'function'){
                //         openCallback(closing_block);
                //     }
                //
                // }



            });

        },

        getBatchTranslations(batch_table,multilangFields,batch_foreign, batch_id){

            return new Promise(resolve => {
                $http.async(this, 'get', `api/batch-translations/get-batch`,
                    {batch: batch_table, batch_id: batch_id, batch_foreign:batch_foreign}, function (resp) {
                        // console.log('resp', resp,this.$store.state['app'].appLocale);
                        this.loading_data = false;
                        let translations = {};
                        let locales = this.$store.state['app'].locales;
                        // for (let i = 0; i < locales.length; i++) {
                        //     translations[locales[i].locale] = '';
                        // }

                        if (resp.data.translations ) {
                            // let locales = this.$store.state['app'].locales;
                            // alert(1);
                            for (let multilangFieldName in multilangFields) {
                                translations[multilangFieldName] = {};
                                // console.log(multilangFieldName);
                                for (let j = 0; j < locales.length; j++) {
                                    translations[multilangFieldName][locales[j].locale] = resp.data.translations[locales[j].locale]? resp.data.translations[locales[j].locale][multilangFieldName] : '';
                                }
                            }

                            // translations = resp.data.translations;
                        }

                        resolve({
                            translations: translations,
                            // data: {
                            //     message: resp.data.message
                            // }
                        });


                    }, false, {});
            });
        },
        assignIndexableTranslations(e){

            this.indexableFields[e.field_name+'_translations'] = e.translations;
            console.log('this.indexableFields',this.indexableFields);
        },

        assignBatchTranslations(e){
// console.log('assignBatchTranslations',e.field_name,e.translations);
            Vue.set(this.multilangFields, e.field_name, e.translations);
        },

        getObjectPropertyByStringPath(object, path){ //
            if (object == null) { // null or undefined
                return object;
            }

            const parts = path.split('.');
            let newObj = Object.assign({},object[parts[0]]);
            for (let i = 1; i < parts.length; ++i) {
                if (newObj == null) { // null or undefined
                    return undefined;
                }
                const key = parts[i];
                newObj = newObj[key];
            }
            return newObj;
        },

        setServerSideErrorsByFieldsVid(errorData){
            if(this.PREFIX && this.$refs[this.PREFIX + '_FORM']){
                for(let fieldVid in errorData){

                    if(this.$refs[this.PREFIX + '_FORM'].errors.hasOwnProperty(fieldVid) || this.$refs[this.PREFIX + '_FORM'].errors.hasOwnProperty(this.$t('app.'+fieldVid))){
                        this.$refs[this.PREFIX + '_FORM'].setErrors({
                            [fieldVid]: errorData[fieldVid]
                        });
                        delete errorData[fieldVid];
                    }

                }
                this.focusOnFormError(true);
            }
        },

        getUserTimeZone(){
           return Intl.DateTimeFormat().resolvedOptions().timeZone; //europe/Minsk
        },

    },

    directives: {//v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        // Ripple,

        // 'b-tooltip':VBTooltip
// <div id="hook-arguments-example" v-demo:foo.a.b="message"></div>

        multilang: {

            inserted: function (el, binding, vnode) {

                el.classList.add("multilang-form-element");

                if(process.env.MIX_IS_MULTILANG_PROGECT != 0) {
                    let clickHandler = function (e) {

                        let wrap = vnode.context.$root.$refs['multilang-modal'];
                        // console.log('wrap', wrap);
                        let modal = wrap.$children[0];
                        wrap.formElementInst = vnode.componentInstance;
                        // wrap.formElementInst = vnode.componentInstance;
                        let $options = binding.value;
                        let indexableData = Object.assign({}, $options);
                        wrap.field_type = indexableData.field_type;
                        if (!indexableData.field_type) wrap.field_type = 'string';

                        if ($options.translation_index_path) {
                            indexableData.translation_index = this.getObjectPropertyByStringPath(vnode.context, $options.translation_index_path);
                            indexableData.translations = this.getObjectPropertyByStringPath(vnode.context, $options.translation_index_path + '_translations');
                        } else {
                            indexableData.translation_index = vnode.context.indexableFields[indexableData.indexable_field_name];
                            indexableData.translations = vnode.context.indexableFields[indexableData.indexable_field_name + '_translations'];
                        }


                        wrap.$set(wrap, 'indexableData', indexableData);
                        // console.log('d---', indexableData);

                        modal.show();
                    };

                    el.__vueClick__ = clickHandler;

                    el.addEventListener("click", el.__vueClick__);
                } else {
                    let formElementInst = vnode.componentInstance;
                    // let $options = binding.value;
                    let directiveData = Object.assign({}, binding.value);

                    let locale = vnode.context.$root.$store.state['app'].appLocale;

                    formElementInst.$on('input', function($event){
                        // alert($event);
                        // console.log('works', directiveData.indexable_field_name,locale,$event);
                        formElementInst.$emit('translation-confirmed', {field_name: directiveData.indexable_field_name, translations:{[locale]:$event}});

                    });
                }


            },

            unbind(el, binding) {
                if(process.env.MIX_IS_MULTILANG_PROGECT != 0) {
                    el.removeEventListener("click", el.__vueClick__);
                    delete el.__vueClick__;
                }

            },


        },
        multilangBatch: {

            inserted: function (el, binding, vnode) {

                el.classList.add("multilang-form-element");


                if(process.env.MIX_IS_MULTILANG_PROGECT != 0){
                    let clickHandler = function (e) {
                        let wrap = vnode.context.$root.$refs['multilang-modal'];
                        let modal = wrap.$children[0];
                        wrap.formElementInst = vnode.componentInstance;
                        wrap.translation_method = 'batch';
                        let $options = binding.value;
                        let directiveData = Object.assign({},$options);
                        wrap.field_type = directiveData.field_type;
                        if(!directiveData.field_type) wrap.field_type = 'string';

                        directiveData.translations = vnode.context.multilangFields;

                        wrap.$set(wrap, 'batchData', directiveData);

                        modal.show();
                    };
                    el.__vueClick__ = clickHandler;

                    el.addEventListener("click", el.__vueClick__);
                } else {
                    let formElementInst = vnode.componentInstance;
                    // let $options = binding.value;
                    let directiveData = Object.assign({},binding.value);

                    let locale = vnode.context.$root.$store.state['app'].appLocale;

                    formElementInst.$on('input', function($event){
                        // alert($event);
                        formElementInst.$emit('translation-confirmed', {field_name: directiveData.field_name, translations:{[locale]:$event}});

                    });

                }


            },

            unbind(el, binding, vnode) {
                if(process.env.MIX_IS_MULTILANG_PROGECT != 0){
                    el.removeEventListener("click", el.__vueKeyup__);
                    delete el.__vueKeyup__;
                }
                    // else {
                //     el.removeEventListener("click", el.__vueKeyup__);
                //     delete el.__vueKeyup__;
                // }
            },
        },
        // v-click-outside="clickOutside"
        'click-outside': {
            bind: function (el, binding, vnode) {
                el.clickOutsideEvent = function (event) {
                    // here I check that click was outside the el and his children
                    if (!(el == event.target || el.contains(event.target))) {
                        // and if it did, call method provided in attribute value
                        // console.log('dsfsdfsd',vnode,vnode.context,binding,binding.expression);
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener('click', el.clickOutsideEvent)
            },
            unbind: function (el) {
                document.body.removeEventListener('click', el.clickOutsideEvent)
            },
        },

    },
    filters: {
        nlToBr(str, is_xhtml) {
            if (typeof str === 'undefined' || str === null) {
                return '';
            }
            let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
        },
        integer(value) {
            return String(value).replace(/\./, '');
        }
        //     prepareDescr: function (html) { //delete tags
        //         var regex = /(<([^>]+)>)/ig;
        //         return html.replace(regex, "").ucFirst();
        //     }
    },

}
