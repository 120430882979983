import {assignLoggedInRoutes} from "@resources/js/routes/loggedUser-routes";
// console.log('itms patient',assignLoggedInRoutes('patient'));

export default {
    path: '/patient',
        component: () => import('@views/layouts/patientLayout'),
    // name: 'patient',
    meta: {
    breadcrumb: ''
},
    children: [
        {
            path: '',
            component: () => import('@views/patient/DashboardPatient'),
            name: 'patient.dashboard',
            meta: {
                breadcrumb: '',
                title:'app.dashboard'
            },
            redirect: to => {//todo temporary
                // console.log('redirect');
                return {name: 'patient.information'}
            },
        },
        {
            path: 'information',
            component: () => import('@views/patient/PagePatientInformation'),
            name: 'patient.information',
            meta: {
                breadcrumb: 'app.information',
                title:'app.information',
                page_content_class: 'content-container container p-0',
            }
        },

        {
            path: 'prescriptions',
            component: () => import('@views/patient/prescriptions/list'),
            name: 'patient.prescriptions',
            meta: {
                breadcrumb: 'app.prescriptions',
                title:'app.prescriptions',
                // page_content_class: 'content-container container',
            }
        },

        {
            path: 'orders/:order_type?',
            component: () => import('@views/patient/orders/list'),
            name: 'patient.orders.list',
            props: true,
            meta: {
                breadcrumb: '',
                title: 'patient.orders'
            }
        },
        // {
        //     path: 'orders',
        //     component: () => import('@views/patient/orders/list'),
        //     name: 'patient.orders',
        //     meta: {
        //         breadcrumb: 'app.orders',
        //         title:'app.orders'
        //     }
        // },
        // {
        //     path: 'time-orders',
        //     component: () => import('@views/patient/orders/list'),
        //     name: 'patient.time_orders',
        //     meta: {
        //         breadcrumb: 'app.time_orders',
        //         title:'app.time_orders'
        //     }
        // },
        {
            path: 'doctors/:doctor_id/checkout',
            component: () => import('@views/patient/checkout/checkout'),
            name: 'patient.doctor_checkout',
            meta: {
                breadcrumb: 'app.checkout',
                title:'app.checkout'
            }
        },

        ...(assignLoggedInRoutes('patient')),


    ]
}