// import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: true,
    // isVerticalMenuActive: true,
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    // UPDATE_VERTICAL_MENU_ACTIVE(state, val) {
    //   state.isVerticalMenuActive = val
    // },
    TOGGLE_VERTICAL_MENU_COLLAPSED(state) {
      state.isVerticalMenuCollapsed = !state.isVerticalMenuCollapsed
    },
    // TOGGLE_VERTICAL_MENU_ACTIVE(state, val) {
    //   state.isVerticalMenuActive = val
    // },
  },
  actions: {},
}
