// import Vue from 'vue'
const loggedInRoutes = [

    {
        path: 'chat',
        component: () => import('@views/logged/chats/entry'),
        name: 'APP_INTERFACE.chat',
        meta: {
            breadcrumb: 'app.chat',
            title: 'app.chat',
            page_id: 'chat-page',
            page_content_class: 'content-container container',
        },

        children: [{
            path: ':user_id',
            component: () => import('@views/logged/chats/entry'),
            name: 'APP_INTERFACE.chat_to_user',
            props: true,
            meta: {
                breadcrumb: '',
                title: 'app.chat',
                page_id: 'chat-page',
                page_content_class: 'content-container container',
            }
        }
        ]
    },
    {
        path: 'logout',
        // component: () => import('@views/patient/orders/list'),
        name: 'APP_INTERFACE.logout',
        meta: {
            // click_action: 'logout',
            breadcrumb: 'app.logout',
            title: 'app.logout',
            click_handler: function(vm, t){
                vm.confirmAction(t('app.logout'), t('app.logout_confirm'), 'warning', (result) => {
                                vm.$user.logout();
                });
            }
        }
    },
    {
        path: 'safety',
        component: () => import('@views/logged/safety/entry'),
        name: 'APP_INTERFACE.safety',
        meta: {
            breadcrumb: 'app.safety',
            title: 'app.safety',
            page_content_class: 'content-container container',
        }
    },
    // {
    //     path: '404',
    //     component: () => import('@views/includes/errors/404'),
    //     name: 'APP_INTERFACE.errors.404',
    //     meta: {
    //         breadcrumb: 'app.404',
    //         title: 'app.404',
    //     }
    // },
    {
        path: '403',
        component: () => import('@views/includes/errors/403'),
        name: 'APP_INTERFACE.errors.403',
        meta: {
            breadcrumb: 'app.403',
            title: 'app.403',
        }
    },
    {
        path: 'no-connection',
        component: () => import('@views/guest/noConnection.vue'),
        name: 'APP_INTERFACE.no_connection',
        meta: {
            breadcrumb: 'app.no_connection',
            title: 'app.no_connection',
        },

    },
];

export function assignLoggedInRoutes(account_owner_type){


    let preparedRoutes = [];

    for(let i=0; i<loggedInRoutes.length; i++){
        let route = Object.assign( {},loggedInRoutes[i]);
        route.name = route.name.replace(/APP_INTERFACE/g, account_owner_type);
        if(route.children){
            let children = [];
            for(let j=0; j<route.children.length; j++){
                let child = Object.assign( {},route.children[j]);
                child.name = route.children[j].name.replace(/APP_INTERFACE/g, account_owner_type);
                children.push(child);
            }
            route.children = children;
        }
        preparedRoutes.push(route);
    }
    return preparedRoutes;

}

export default loggedInRoutes;
